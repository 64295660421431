// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

// import styles bundle
import 'swiper/css/bundle';
import 'swiper/css/a11y';

const { _x } = wp.i18n;

new Swiper('.trainings__carousel', {
  loop: false,
  slidesPerView: 'auto',
  watchOverflow: true,

  // Navigation arrows
  navigation: {
    nextEl: '.trainings__carousel__navigation .swiper-button-next',
    prevEl: '.trainings__carousel__navigation .swiper-button-prev',
  },
  a11y: {
    prevSlideMessage: _x('Previous slide', 'swiper accessibility', 'vtx'),
    nextSlideMessage: _x('Next slide', 'swiper accessibility', 'vtx'),
    slideLabelMessage:
      _x('Slide ', 'swiper accessibility', 'vtx') +
      '{{index}}' +
      _x(' of ', 'swiper accessibility', 'vtx') +
      '{{slidesLength}}',
  },
  keyboard: {
    enabled: true,
  },
});
